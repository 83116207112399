<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
<div class="app-container">
  <jm-header></jm-header>
  <main class="page-contents" id="main" #main>
    <ng-container *ngIf="!loading; else appLoading">
      <router-outlet
        *ngIf="(!mnts.activeMaintenanceEvent || ignoreMaintenance) && !inits.initializationError"
      ></router-outlet>
<!--       <button pButton (click)="throw_test()" label="Throw a test error"></button> -->
      <jm-page-not-found
        *ngIf="inits.initializationError && !mnts.activeMaintenanceEvent"
      ></jm-page-not-found>

      <jm-maintenance-message
        *ngIf="mnts.activeMaintenanceEvent && !ignoreMaintenance"
        [event]="mnts.activeMaintenanceEvent"
      ></jm-maintenance-message>
    </ng-container>
    <ng-template #appLoading>
      <jm-loading-spinner
        [showMessage]="false"
        [useDots]="false"
      ></jm-loading-spinner>
    </ng-template>
    <jm-footer></jm-footer>
  </main>
</div>
