<!-- <p-dialog [(visible)]="attributionVisible" header="Attribution">
  <jm-attribution></jm-attribution>
</p-dialog> -->
<p-dialog
  *ngIf="shouldShowWIPDisclaimer"
  [(visible)]="devDisclaimerOpen"
  header="Development Disclaimer"
>
  <div class="dev-disclaimer-nav-content">
    <div
      *ngIf="dev; else prodWIPDisclaimer"
      class="disclaimer-content"
      id="dev-disclaimer"
    >
      <span class="disclaimer-message"
      >You are currently viewing the development build, which may be
        unfinished or buggy.</span
      >
      <a
        (click)="exss.prodSite.open()"
        [href]="exss.prodSite.address"
        class="prod-nav-btn p-button p-button-raised p-button-primary"
        label="Return to production site"
        pButton
      ></a>
    </div>
    <ng-template #prodWIPDisclaimer>
      <div class="disclaimer-content" id="prod-disclaimer">
        <span class="disclaimer-message"
        >This portfolio is a work in progress. Come back later to see what
          changes!</span
        >
      </div>
    </ng-template>
  </div>
</p-dialog>

<div class="footer-container">
  <div class="footer-left">
    <div *ngIf="shouldShowWIPDisclaimer" class="dev-build-disclaimer-container">
      <button
        (click)="devDisclaimerOpen = true"
        [disabled]="devDisclaimerOpen"
        class="disclaimer-icon p-button p-button-text"
        icon="pi pi-exclamation-triangle"
        label="WIP"
        pButton
      ></button>
    </div>
    <jm-tracking-opt-in/>
  </div>
  <div class="social-icons-wrapper">
    <jm-social-icons/>
  </div>
</div>
