<div class="under-maintenance-container">
  <div class="maintenance-text-wrapper">
    <span class="maintenance-header">Under Maintenance. Be back soon!</span>
    <span
      *ngIf="event.message && event.message.length"
      class="maintenance-message"
      >{{ event.message }}</span
    >
  </div>
  <div class="spinner">
    <div class="loadingio-spinner-gear-83xsmw1onxc">
      <div class="ldio-22g73ngrsc9">
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
