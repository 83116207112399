<div class="social-icons-container">
  <a
    (click)="exss.onClickSocialIcon(site)"
    *ngFor="let site of exss.externalSites"
    [attr.aria-label]="site.alt"
    [href]="site.address"
    [icon]="site.icon || ''"
    [pTooltip]="site.label"
    [target]="site.openInNewTab ? '_blank' : '_self'"
    class="site-link p-button p-button-text {{ site.styleClass }}"
    pButton
    role="link"
  ></a>
</div>
