<button
  (click)="ths.toggleLightDark()"
  *ngIf="ths.activeTheme; else themeNotLoadedYet"
  [attr.aria-label]="ths.darkMode ? 'Switch to light mode' : 'Switch to dark mode'"
  [icon]="ths.activeTheme.icon"
  [pTooltip]="ths.darkMode ? 'Switch to light mode' : 'Switch to dark mode'"
  class="p-button p-button-text p-button-outline"
  pButton
></button>
<ng-template #themeNotLoadedYet>
  <jm-loading-spinner [showMessage]="false" [useDots]="false"/>
</ng-template>
